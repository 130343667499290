<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <v-card>
      <v-card-title class="flex d-flex justify-space-between align-center"
      style="border-top: 5px solid #00A14B">
        <div class="">
          Email Credentials
        </div>
        <v-btn icon small @click="$emit('close')">
          <v-icon size="18">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5">
        <label class="caption mx-2">
          UPLOAD CSV FILE
        </label>
        <v-file-input
          prepend-icon=""
          prepend-inner-icon="mdi-file-outline"
          append-icon="mdi-file-upload-outline"
          @click:append="submit"
          v-model="file"
          hide-details=""
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['dialog'],
  data: () => ({
    file: ''
  }),
  methods: {
    ...mapActions('admin', [
      'postCredentials'
    ]),

    submit() {
      let form = new FormData()

      form.append('file', this.file)
      this.postCredentials(form).then(() => {
        this.$emit('save')
      })
    }
  }
}
</script>