<template>
  <section id="main">
    <v-app v-if="!loading">
      <Topbar @close="drawer = !drawer" :user="user" :email_count="email_count"/>
      <Leftbar :drawer="drawer" :user="user" />
      <v-main :class="user.dark == 1 ? '' : 'main'">
        <v-container>
          <router-view @vouche="addVoucher" />
        </v-container>
      </v-main>
    </v-app>

    <v-app v-else>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="4">
            <div class="d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                color="success"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app>

    <Voucher 
    :addv="addv" 
    :absolute="absolute" 
    @save="voucherSave" 
    @close="addv = false, absolute =false" />

    <snackbar
      :snack="snack"
      :timeout="timeout"
      :text="text"
      @close="snack = false"
    />
  </section>
</template>

<script>
import Topbar from "./Topbar.vue";
import Leftbar from "./Leftbar.vue";
import Voucher from "../components/voucher/VoucherForm.vue";
import { mapActions, mapState } from "vuex";
// import io from "socket.io-client";

export default {
  components: {
    Topbar,
    Leftbar,
    Voucher,
  },
  data: () => ({
    drawer: true,
    loading: true,
    snack: false,
    addv: false,
    level: "",
    selected_courses: "",
    socket: '',
    text: '',
    timeout: 0,
    absolute: false
  }),
  computed: {
    ...mapState("admin", {
      user: (state) => state.user,
      email_count: (state) => state.email_count,
    }),
  },
  mounted() {
    this.emailCountAction()

    // this.socket = io.connect(this.$helpers.socket_end_point(), {});
    // this.socket.on("connect", () => {console.log("Admin connected");});

    this.$vuetify.theme.dark = true;
    this.$store.dispatch("admin/authAction").then(() => {
      this.loading = false;
      this.$vuetify.theme.dark = true;
      if (!this.user.dark) {
        console.log("not dark");
        this.$vuetify.theme.dark = false;
      }
      return;
    });
    
    // let ioEnrolled = `${this.$helpers.tenant()}-voucher-enrolled-channel:App\\Events\\VoucherEnrolledEvent`
    // let vm = this
    // this.socket.on(ioEnrolled, function (message) {
    //   console.log(message.code);
    //   vm.vouchersGetAction()
    //   vm.snack = false
    //   vm.text = `Proccess of voucher code ${message.code} is completed!`
    //   vm.snack = true
    //   vm.timeout = 4000
    //   return
    // })
  },
  methods: {
    ...mapActions('admin', [
      'vouchersGetAction',
      'emailCountAction'
    ]),

    addVoucher() {
      this.$store.dispatch("admin/allCoursesAction");
      this.$store.dispatch("admin/getUserCategory");
      this.addv = true;
      this.absolute = true;
    },

    voucherSave() {
      this.text = 'Proccess is on queue...'
      this.snack = true
      this.timeout = 2000
    }
  },
};
</script>