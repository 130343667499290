<template>
  <section id="topbar">
    <v-app-bar color="transparent" elevation="0" height="100" app>
      <v-toolbar rounded="lg" elevation="0">
        <v-app-bar-nav-icon @click="$emit('close')" />
        <v-spacer></v-spacer>

        <v-btn class="button primary--text font-weight-bold" text @click="dialog=true">
          <v-icon left>
            mdi-email
          </v-icon>
          Email Credentials
        </v-btn>
        <v-badge :content="email_count" class="mt-3 mr-8"/>
        <v-btn icon @click="mode">
          <v-icon>{{
            !user.dark ? "mdi-weather-night" : "mdi-weather-sunny"
          }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-app-bar>
    <Email :dialog="dialog" @save="save" @close="dialog=false"/>
    <snackbar 
    :snack="snack"
    text="Credentials Sent!"
    timeout="2000"
    @close="snack=false"/>
  </section>
</template>

<script>
import Email from './Email.vue'

export default {
  props: ["user", "email_count"],
  components: {
    Email
  },
  data: () => ({
    dialog: false,
    snack: false
  }),
  methods: {
    mode() {
      this.$store.dispatch("admin/theme");
      if (!this.user.dark) {
        this.$vuetify.theme.dark = true;
        return;
      }
      this.$vuetify.theme.dark = false;
    },

    save() {
      this.dialog = false
      this.snack = true
    }
  },
};
</script>