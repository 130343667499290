<template>
  <v-navigation-drawer
    style="z-index: 9999"
    width="500"
    left
    absolute
    stateless
    hide-overlay
    :value="addv"
  >
    <v-toolbar flat height="100">
      <v-toolbar-title class="d-flex align-center">
        <v-btn icon class="mr-2" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="text-h4">Generate Voucher</div>
      </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mt-10 mx-2">
      <v-card-text>
        <v-form>
          <label class="caption">COURSES</label>
          <v-select
            dense
            color="primary"
            hint="Select subjects to be enrolled"
            persistent-hint
            multiple
            item-color="primary"
            :items="courses"
            item-text="title"
            item-value="id"
            chips
            clearable
            v-model="selected_courses"
            class="mb-10"
          />
          <label class="caption">USER LEVEL</label>
          <v-select
            dense
            item-color="primary"
            multiple
            chips
            clearable
            :items="category"
            item-text="name"
            item-value="id"
            hint="Select level of users"
            v-model="level"
            persistent-hint
            v-on:input="getStudents($event)"
          />

          <v-alert v-if="Object.entries(errors).length > 0" type="danger">
            <div class="caption">
              <v-icon left>mdi-circle-medium</v-icon>
              {{ errors.level[0] }}
            </div>
            <div class="caption">
              <v-icon left>mdi-circle-medium</v-icon>
              {{ errors.courses[0] }}
            </div>
            <div class="caption">
              <v-icon left>mdi-circle-medium</v-icon>
              {{ errors.students[0] }}
            </div>
          </v-alert>

          <v-btn
            v-if="!loading"
            @click="generate"
            block
            large
            color="primary"
            class="mt-10"
          >
            Generate
          </v-btn>
          <v-btn v-else block large color="primary" class="mt-10">
            Generating...
          </v-btn>
        </v-form>

        <v-card class="mt-10" v-if="students.length > 0">
          <v-card-text>
            <v-text-field
              label="Search User"
              v-model="search"
              single-line
              class="mx-5"
            />
            <v-data-table
              :headers="headers"
              :items="students"
              :items-per-page="10"
              class="elevation-1"
              :search="search"
              v-model="selected"
              show-select
            >
              <template v-slot:[`item.level`]="{ item }">
                <v-select :items="category" 
                solo 
                dense
                v-model="item.level" 
                item-text="name" item-value="id" readonly
                hide-details
                flat/>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["addv", 'absolute'],
  data: () => ({
    headers: [
      { text: "Email", 
        value: "email", 
        align: "start",
        sortable: false, },
      { text: "Member Type",value: "level", },
      { text: "First Name", value: "firstname" },
      { text: "Last Name", value: "lastname" },
    ],
    level: "",
    selected_courses: "",
    loading: false,
    search: "",
    selected: [],
  }),
  computed: {
    ...mapState("admin", {
      courses: (state) => state.courses,
      students: (state) => state.students,
      category: (state) => state.user_category,
      errors: (state) => state.errors,
    }),
  },
  methods: {
    getStudents(e) {
      this.$store.dispatch("admin/getStudentLevelAction", {
        level: e,
      });
    },
    close() {
      this.$emit("close");
      this.selected_courses = "";
      this.selected = [];
      this.level = "";
      this.$store.commit("admin/studentsMutation", []);
      this.$store.commit("admin/coursesMutation", []);
    },
    generate() {
      console.log(this.level);
      console.log(this.selected_courses);
      console.log(this.selected);
      this.loading = true;
      if (this.selected.length <= 0) {
        alert("Please Select Student!");
        this.loading = false;
        return;
      }
      this.$store
        .dispatch("admin/generateVoucherAction", {
          level: this.level,
          courses: this.selected_courses,
          students: this.selected,
        })
        .then(() => {
          this.loading = false;
          if (this.$errors("admin")) {
            console.log("errors");
            return;
          }
          this.close();
          this.$emit("save");
        });
    },
  },
};
</script>